<template>
  <div class="weetPlayerAnnotation">
    <!-- ANNOTATION FRAME -->
    <div class="annotationPositionFrame" :id="idParentFrame" v-if="mustShowInteraction"
    :style="frameDimention">
      <!-- list of interaction -->
      <InteractionElementPlayerComponent :weet="weet" :time-player="timePlayer" :interaction="interaction"
                                        v-for="interaction in annotationToShow"
                                        v-if="isInteraction(interaction.type) && timePlayer>499"
                                         @onCardEdit="captureEvent"
                                        :key="'interactionPlayer'+interaction.annotationID+interaction.time+interaction.duration"
                                         :parentID="idParentFrame"/>
      <!-- list of annotation -->
      <AnnotationElementPlayerComponent :weet="weet"
                                        v-for="annotation in annotationToShow"
                                        :time-player="timePlayer" :annotation="annotation"
                                        @onEdit="captureEvent"
                                        v-if="!isInteraction(annotation.type)"
                                        :key="'annotationnPlayer'+annotation.annotationID+annotation.time+annotation.duration"
                                        :miniMode="miniMode"
                                        :parentID="idParentFrame"
                                        />
      <transition name="fade">
        <div class="clickCatcher" @click="catchClick" v-show="needToCaptureEvent"></div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Weet} from "@/store/weet/weetModel";
import {Annotation, AnnotationType} from "@/store/annotation/annotationModel";
import AnnotationListElement from "@/components/recorder/annotation/AnnotationListElement.vue";
import InteractionElementPlayerComponent
  from "@/components/play/player/interactions/InteractionElementPlayerComponent.vue";
import {v4 as uuidv4} from 'uuid';
import delay from "delay";
import store from "@/store";
import debounce from "lodash/debounce";
import AnnotationElementPlayerComponent
  from "@/components/play/player/annotations/AnnotationElementPlayerComponent.vue";

@Component({
  components: {AnnotationElementPlayerComponent, InteractionElementPlayerComponent, AnnotationListElement},
})
export default class WeetPlayerAnnotations extends Vue {

  @Prop({default: 0})
  private timePlayer!: number;
  @Prop()
  private weet!: Weet;

  @Prop({default:false})
  private miniMode!:boolean

  private frameWidth=0;
  private frameHeight=0;

  private idParentFrame='';

  private mustShowInteraction=false;

  private needToCaptureEvent=false;

  private onCatchClick:Function|null=null;

  private captureEvent(needToCaptureEvent:boolean,callback:Function|null){
    this.onCatchClick=callback;
    return this.needToCaptureEvent=needToCaptureEvent;
  }

  private debounceResize = debounce(async (el) => {
    const videoLayout=document.querySelector('video[mainVideoPlayer=true]') as HTMLVideoElement;
    if(!videoLayout){
      return;
    }
    // get video size
    const widthVideo=videoLayout.videoWidth;
    const heightVideo=videoLayout.videoHeight;
    const ratioVideo=widthVideo/heightVideo;
    // get tagLayout
    const tagWidth=videoLayout.clientWidth;
    const tagHeight=videoLayout.clientHeight;
    // ratio frame
    const ratioFrame=tagWidth/tagHeight;

    if((16/9)>=ratioVideo){

      // so now compute the height of the video (real)
      let height=tagWidth/ratioVideo;
      const width=0;
      if(height>tagHeight){
        // so the video cannot be put on the frame, we have to compute
        // base on the height
        height=tagHeight;
      }
      el.frameHeight=height;
      el.frameWidth=width;
    }else{
      var width=tagHeight*ratioVideo;
      el.frameHeight=-1;
      el.frameWidth=width;
    }
  }, 50);

  get listOfAnnotation(): Annotation[] {
    return this.weet.annotations||[];
  }

  get isFullscreen(): boolean {
    return store.getters.isFullscreenMode;
  }

  get frameDimention():string{
    if(this.frameWidth>this.frameHeight){
      return 'width:'+this.frameWidth+'px';
    }else{
      return 'height:'+this.frameHeight+'px';
    }
  }

  get annotationToShow(): Annotation[] {
    // 500ms to keep the animation out
    return this.listOfAnnotation.filter((annotation: Annotation) => {
      return annotation.time <= this.timePlayer
          && (annotation.time + annotation.duration + 500) >= this.timePlayer
    })
  }

  public async mounted(){
    this.idParentFrame=uuidv4();
    this.mustShowInteraction=true;
    this.recomputeInteractionFrame();
    window.addEventListener('resize',this.recomputeInteractionFrame)
  }
  private isInteraction(type:AnnotationType){
    return type===AnnotationType.INTERACTION;
  }

  @Watch('isFullscreen')
  private async onFullSreenChange(){
    this.mustShowInteraction=false;
    await delay(1500);
    // this.recomputeInteractionFrame()
    this.mustShowInteraction=true;
  }

  private recomputeInteractionFrame(){
    this.debounceResize(this);
  }

  private catchClick(){
    if(this.onCatchClick){
      this.onCatchClick();
    }
  }
  beforeDestroy(){
    window.removeEventListener('resize',this.recomputeInteractionFrame);
  }


}
</script>

<style lang="scss" scoped>
@import '@/scss/shadows.scss';
.annotationPositionFrame{
  background: rgba(255,0,0,0);
  aspect-ratio: 16/9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
}
.clickCatcher{
  background-color: rgba(27, 57, 84, 0.5);
  position: absolute;
  top:-100vh;
  left: -100vw;
  width: 200vw;
  height: 200vh;
  pointer-events: all;
}
</style>
