import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {User} from '@/store/user/userModel';
import {FilterVideoType} from '@/enum/FilterVideoEnum';


export class Annotation {

    public annotationID: string = '';  //Functional ID
    public time: number = 0;
    public duration: number = 0;
    public type = AnnotationType.TEXT; // Type Of annotation
    public theme = AnnotationTheme.DEFAULT // Theme of the annoation
    public positionType = AnnotationPosition.TOP_LEFT; // Position of annotation
    public iconMD = "";
    public profil = false;
    public urlContent = "";
    public textContent = "";
    public interactionUrl = "";
    public exportStaticUrl = "";
    public positionX: number = 0;
    public positionY: number = 0;
    public width:number=0;
    public height:number=0;
    public colorFront:string="";
    public colorBack:string="";
    public user: User | null = null;
    public sound: boolean = false;
    public annotationCard:AnnotationCard=new AnnotationCard();
}

export class AnnotationCard{
    public annotationCardID:string="";
    public title:string="";
    public description:string="";
    public interactionUrl:string="";
    public interactionLabel:string="";
    public imageUrl:string="";
    public type:string="";
    public alwaysShow:boolean=true

    public exportStaticUrl = "";
}

export enum AnnotationType {
    TEXT = "TEXT",
    IMAGE = "IMAGE",
    INTERACTION = "INTERACTION"
}

export enum AnnotationTheme {
    DEFAULT = "DEFAULT"
}

export enum AnnotationPosition {
    TOP_LEFT = "TOP_LEFT",
    TOP_RIGHT = "TOP_RIGHT",
    BOTTOM_RIGHT = "BOTTOM_RIGHT",
    FREE="FREE"
}

export enum AnnotationCardType{
    SIMPLE_CARD="SIMPLE_CARD"
}
