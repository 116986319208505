import { render, staticRenderFns } from "./CreateWeetAnnotationMode.vue?vue&type=template&id=b8719bca&scoped=true"
import script from "./CreateWeetAnnotationMode.vue?vue&type=script&lang=ts"
export * from "./CreateWeetAnnotationMode.vue?vue&type=script&lang=ts"
import style0 from "./CreateWeetAnnotationMode.vue?vue&type=style&index=0&id=b8719bca&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8719bca",
  null
  
)

export default component.exports