<template>
  <b-colorpicker position="is-top-right" :representation="'square'"
                 :value="value" v-model="realValue"
  @input="onInputChangeValue">
    <template #footer="{ color }">
      <w-input :value="color+''" @input="onInputChangeValue"/>
    </template>
  </b-colorpicker>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WInput from "@/components/wrapper/w-input.vue";
import Color from 'buefy/src/utils/color'

@Component({
  components: {WInput}
})
export default class WColorPicker extends Vue {

  @Prop({default: 'blue'})
  private value!: string;


  private realValue: string='#777777';

  private mounted() {
    this.realValue=this.value;
  }

  public onInputChangeValue(color: string) {
    if (this.isValidHexColor(color + "")) {
      this.realValue = color+"";
      this.$emit('input', this.realValue);
    }
  }


  private isValidHexColor(color) {
    // Regular expression for RGB or RGBA hex format
    const regex = /^(#(?:[0-9a-fA-F]{3}){1,2}|rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})(?:\s*,\s*(\d(\.\d+)?|\.\d+))?\s*\))$/;

    // Test the color string against the regular expression
    return regex.test(color);
  }


  @Watch('value')
  public onChangePropValue() {
    this.realValue = this.value;
  }

}
</script>

<style scoped lang="scss">
</style>
