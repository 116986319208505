<template>
  <div class="colorHelperContainer">
    <transition name="zoom" >
      <div v-show="selectedAnotation">
        <div class="colorLine">
          <div class="labelColorLine">{{$t('createWeetComponent.advancedEditing.annotationMode.color.front')}}</div>
          <w-color-picker :value="selectedAnotation?.colorFront" @input="onUpdateFrontColor"/>
        </div>
        <div class="colorLine">
          <div class="labelColorLine">{{$t('createWeetComponent.advancedEditing.annotationMode.color.back')}}</div>
          <w-color-picker :value="selectedAnotation?.colorBack" @input="onUpdateBackColor"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from "@/store";
import {Annotation} from "@/store/annotation/annotationModel";
import {clone} from "@/utils/cloneUtil";
import WColorPicker from "@/components/wrapper/w-colorPicker.vue";


@Component({
  components: {
    WColorPicker
  },
})
export default class AnnotationColorationHelper extends Vue {

  private frontColor:string="black";
  private backColor:string="white";
  get selectedAnotation(): Annotation | undefined {
    return store.getters.getAnnotationSelected;
  }

  @Watch('selectedAnotation')
  private onSelectAnnotation(){
    this.frontColor=this.selectedAnotation?.colorFront||"black";
    this.backColor=this.selectedAnotation?.colorBack||"white";
  }

  private onUpdateFrontColor(color:string){
    this.frontColor=color;
    this.onUpdateColor();
  }

  private onUpdateBackColor(color:string){
    this.backColor=color;
    this.onUpdateColor();
  }

  private onUpdateColor(){
    var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
    annotationUpdated.colorFront=this.frontColor;
    annotationUpdated.colorBack=this.backColor;
    this.$emit('onUpdateAnnotation', annotationUpdated);
  }


}
</script>

<style scoped lang="scss">
.colorHelperContainer {
  padding-left: 16px;
}

</style>
